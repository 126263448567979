<template>
	<!-- 添加表单 -->
	<div class="-edit-main">
		<el-form class="-form" :model="form" :rules="rules" :ref="setting.formName" @submit.native="_formSubmit()" @submit.native.prevent>
			<el-select v-model="form.classId">
				<el-option v-for="(item, index) in _model.options.classId" :key="index" :label="item.label" :value="item.id"></el-option>
			</el-select>
			<el-form-item label="起始时间" prop="startTime">
				<el-date-picker v-model="form.startTime" type="datetime" value-format="YYYY-MM-DD HH:mm:ss"></el-date-picker>
			</el-form-item>
			<el-form-item label="截至时间" prop="endTime">
				<el-date-picker v-model="form.endTime" type="datetime" value-format="YYYY-MM-DD HH:mm:ss"></el-date-picker>
			</el-form-item>
			<el-form-item label="合计数量" prop="totals">
				<el-input v-model="form.totals" placeholder="" />
			</el-form-item>
			<el-form-item label="券码" prop="itemNo" class="-full">
				<el-input v-model="form.itemNo" :rows="3" type="textarea" placeholder="多个请用换行分割" />
			</el-form-item>
			<el-form-item label="介绍" prop="intro" class="-full">
				<el-input v-model="form.intro" :rows="3" type="textarea" placeholder="" />
			</el-form-item>
			<div class="-edit-footer">
				<el-button type="primary" native-type="submit" title="按下'Ctrl + Enter'键可快速提交">提 交</el-button>
				<el-button title="按下'Esc'可快速关闭" @click="closeDrawer()">取 消</el-button>
			</div>
		</el-form>
		
	</div>
	
</template>

<script>
import { reactive } from 'vue'
import _edit  from '@/assets/js/edit'
import _model from '@/static/model/portalCode/batch'
export default {
	props: [ 'editId' ],
	emits: [ 'onClose' ],
	setup(props, context) {
		return {
			context,
			closeDrawer: ()=>{context.emit('onClose', 'edit')},
			view: reactive({}),
			form: reactive({}),
			_model: _model,
			options: reactive({}),
			setting: reactive({
				moduleApi: '/Mar/Market/',
				moduleName: '券码添加',
				// addDetailApi: true,
			}),
			rules: {
				mchId: [
						{ required: true, message: '请选择商户', trigger: 'blur' },
					],
				classId: [
							{ required: true, message: '请选择电子券类别', trigger: 'blur' },
						],
				intro: [
						{ required: true, message: '请填写券码使用说明', trigger: 'blur' },
					],
				itemNo: [
						{ required: true, message: '请输入券码', trigger: 'blur' },
					],
				totals: [
						{ required: true, message: '请输入券码数量', trigger: 'blur' },
				],
				startTime:	[
						{ required: true, message: '请选择起始时间', trigger: 'blur' },
				],		
				endTime:	[
						{ required: true, message: '请选择截至时间', trigger: 'blur' },
				]	
		
			},
		}
	},
	
	mounted: function(){
		this._load(this, _edit)
	},
	methods: {
		
	}
}
</script>


